import { InternalAccount } from '@webapps/numeral-ui-core'
import { EMPTY_CHAR_SYMBOL } from '@/constants'

export function getInternalAccountFormInitialValues(): Partial<InternalAccount> {
    return {
        type: undefined,
        name: EMPTY_CHAR_SYMBOL,
        holder_name: EMPTY_CHAR_SYMBOL,
        account_number: EMPTY_CHAR_SYMBOL,
        connected_account_ids: [],
        created_at: EMPTY_CHAR_SYMBOL,
        id: EMPTY_CHAR_SYMBOL,
        status: undefined
    }
}
