import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { InternalAccountType, InternalAccountTypeSchema } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Select } from '@/components/@misc'
import { prettyPrintCasedWords } from '@/utils'
import { getFormSelectPlaceholderByReactQueryState } from '@/components'

export const InternalAccountTypeInput: React.FC<FormikInput> = ({
    name,
    value,
    customPlaceholderKey,
    customPlaceholderValues,
    isPlaceholderDisplayed,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<InternalAccountType>({ name, value })
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey }, customPlaceholderValues)
        }
        return getFormSelectPlaceholderByReactQueryState(intl, null, isPlaceholderDisplayed)
    }, [customPlaceholderKey, customPlaceholderValues, intl, isPlaceholderDisplayed])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<InternalAccountType>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={helpers.setValue}
                isInvalid={isInvalid}
                isSearchable={false}
                options={InternalAccountTypeSchema.options}
                getOptionLabel={prettyPrintCasedWords}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
