import { Form, FormPageFooter, FormSection } from '@/components'
import { ApiObjectTypeSchema, InternalAccount, InternalAccountTypeSchema } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { InternalAccountFormProps } from './InternalAccount.form.types'
import { getInternalAccountFormInitialValues } from './InternalAccount.form.utils'
import { ConnectedAccountsInput, InternalAccountTypeInput, TextInput } from '@/components/@inputs'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { CustomFieldsSection } from '@/pages'

export const InternalAccountForm: React.FC<InternalAccountFormProps> = ({ disabled, loading, onSubmit = noop }) => {
    const initialValues = useMemo(getInternalAccountFormInitialValues, [])
    return (
        <Form<Partial<InternalAccount>> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                const isBankCodeInputInputDisplayed = values.type === InternalAccountTypeSchema.enum.own
                return (
                    <>
                        <FormSection titleId="Account settings">
                            <TextInput
                                name="name"
                                customPlaceholderKey="app.counterparties.internal_accounts.create.form.inputs.name.placeholder"
                                isDisabled={disabled}
                                isRequired={true}
                                data-testid="name"
                            />
                            <ConnectedAccountsInput
                                name="connected_account_ids[0]"
                                customPlaceholderKey="app.counterparties.internal_accounts.create.form.inputs.connected_account_ids.placeholder"
                                isDisabled={disabled}
                                data-testid="connected_account_ids"
                            />
                            <InternalAccountTypeInput
                                name="type"
                                customPlaceholderKey="app.counterparties.internal_accounts.create.form.inputs.type.placeholder"
                                isDisabled={disabled}
                                isRequired={true}
                                data-testid="type"
                            />
                        </FormSection>
                        <FormSection titleId="Account details">
                            <AccountDetailsForm
                                isLookupAvailable={true}
                                isDisabled={disabled}
                                isBankCodeInputInputDisplayed={isBankCodeInputInputDisplayed}
                                isRequired={true}
                            />
                        </FormSection>
                        <OptionalAccountHolderAddressForm isDisabled={disabled} />
                        <CustomFieldsSection<InternalAccount>
                            objectType={ApiObjectTypeSchema.enum.internal_account}
                            isDisabled={disabled}
                            isExpanded={false}
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.counterparties.internal_accounts.create.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
